<template>
    <v-row class="">
        <v-col sm="12" cols="12" class="pt-0">
            <h2 class="mb-0 font_20">{{ $t('sale_channel') }}</h2>
            <p class="mb-0">{{ $t('sale_channel_desc') }} </p>
        </v-col>

        <v-col sm="12" cols="12">
            <template>
                <LoadingMe
                    :isLoading="showLoading"
                    :fullPage="false"
                    :myLoading="true"/>
                <v-simple-table class="attachment_table">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>{{ $t('name') }}</th>
                            <th>{{ $t('nature') }}</th>
                            <th>{{ $t('use') }}</th>
                            <th>{{ $t('set_default') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="pl in saleChannelList" v-bind:key="pl.id">
                            <td class="text-bold">{{ pl.name }}</td>
                            <td>{{ pl.nature }}</td>
                            <td>
                                <v-switch
                                    color="primary"
                                    v-model="pl.isUsed"
                                    :label="pl.isUsed ? 'YES' : 'NO'"
                                />
                            </td>
                            <td>
                                <v-switch
                                    color="primary"
                                    @change="defaultChange(pl)"
                                    v-model="pl.isDefault"
                                    :label="pl.isDefault ? 'YES' : 'NO'"
                                />
                            </td>
                        </tr>
                        <LoadingMe
                            :isLoading="showLoading"
                            :fullPage="false"
                            :myLoading="true"/>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </template>
            <div class="function_footer">
                <v-btn color="primary" class="float-right white--text text-capitalize"
                       @click="onSaveClose">
                    {{ $t('save') }}
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import SaleChannelSettingModel from "@/scripts/model/pos/SaleChannelSetting"
import kendo from "@progress/kendo-ui";

const $ = kendo.jQuery;
const saleChannelHandler = require("@/scripts/saleChannelHandler")
const posHandler = require("@/scripts/posHandler")

export default {
    components: {
        LoadingMe: () => import(`@/components/Loading`),
    },
    data: () => ({
        dialogM2: '',
        showLoading: true,
        natures: [
            'Online', 'Offline'
        ],
        mNature: '',
        s: new SaleChannelSettingModel({}),
        saleChannelList: []
    }),
    props: {},
    computed: {},
    watch: {},
    created() {
    },
    methods: {
        async onSaveClose() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    this.showLoading = true
                    this.s.saleChannel = this.saleChannelList
                    posHandler.saleChannelSettingPost(new SaleChannelSettingModel(this.s)).then(response => {
                        this.showLoading = false
                        if (response.data.statusCode === 201) {
                            this.$snotify.success('Success')
                        }
                    }).catch(e => {
                        this.showLoading = false
                        this.$snotify.error('Something went wrong')
                        this.errors.push(e)
                    })

                }, 300);
            })
        },
        async loadSaleChannel() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    this.showLoading = true
                    saleChannelHandler.get().then(res => {
                        if (res.data.statusCode === 200) {
                            this.showLoading = false
                            this.saleChannelList = []
                            let data = res.data.data
                            if(data.length > 0){
                                let self = this
                                $.each(data, function (i,v){
                                    self.saleChannelList.push({
                                        id: v.id,
                                        name: v.name,
                                        nature: v.nature,
                                        isUsed: false,
                                        isDefault: false
                                    })
                                })
                                this.loadSaleChannelSetting()
                            }
                        } else {
                            this.showLoading = false
                        }
                    })
                }, 300);
            })
        },
        async loadSaleChannelSetting() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.showLoading = true
                    posHandler.saleChannelSettingGet().then(response => {
                        if (response.data.statusCode === 200) {
                            this.showLoading = false
                            let data = response.data.data
                            if (data.length > 0) {
                                this.s = data[0]
                                $.each(this.saleChannelList, function (i, v) {
                                    let c = data[0].saleChannel.filter((obj) => {
                                        return obj.id == v.id
                                    })
                                    if (c.length > 0) {
                                        v.isUsed = c[0].isUsed
                                        v.isDefault = c[0].isDefault
                                    }
                                })
                            }
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
        defaultChange(e){
            $.each(this.saleChannelList, function (i,v){
                if(v.id != e.id){
                    v.isDefault = false
                }
            })
        },
    },
    mounted: async function () {
        await this.loadSaleChannel()
    }
};
</script>